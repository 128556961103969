<template>
  <div style="margin: -15px -15px;background-color: #fff;">
    <div class="videoLib-top">
      <div>
        <span>选择年份：</span>
        <a-date-picker
            v-model="year"
            mode="year"
            v-decorator="['year']"
            placeholder="请输入年份"
            format="YYYY"
            class="input"
            :open="yearShowOne"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
      </div>
      <div>
        <span>专家姓名：</span>
        <a-input placeholder="请输入专家姓名" class="input" v-model="expertsName"/>
      </div>
      <div>
        <span>视频标题：</span>
        <a-input placeholder="请输入视频标题" class="input" v-model="videoTitle"/>
      </div>
      <div>
        <a-button @click="lookBtn" type="primary">查询</a-button>
        <a-button @click="restBtn" style="margin-left: 15px">重置</a-button>
      </div>
    </div>
    <div class="videoLib-content">
      <div class="r-btn">
          <a-button @click="newProject" type="primary">上传视频</a-button>
        </div>
      <div>
          <a-table :pagination="false" :columns="columns" :data-source="data" :row-key="record => record.id">
            <span slot="name" slot-scope="text, record">
              <a @click="titleBtn(record.id)">{{ text }}</a>
            </span>
            <span slot="names" slot-scope="text, record">
              <span>{{record.name}} - {{record.hospital}}</span>
            </span>
            <span slot="action" slot-scope="text, record">
              <a @click="restBtn_table(record)">修改</a>
              <a-popconfirm
                  style="margin-left: 8px"
                  title="是否确认删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="confirm(record.id)"
              >
                <a>删除</a></a-popconfirm>
            </span>
          </a-table>
        <div style="width: 100%;margin-top: 15px">
          <div style="float: right;margin-right: 20px">
            <a-pagination
                show-quick-jumper
                :default-current="pageNum"
                :total="total"
                @change="onChange" />
          </div>
        </div>
        </div>
    </div>
    <!--------------------------------------------------对话框------------------------------------------->
    <a-drawer
        title="上传视频"
        :width="700"
        :visible="visible_upVideo"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_video"
    >
      <a-form-model
          ref="content_ruleForm"
          :model="videoForm"
          :rules="content_video_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol">
        <a-form-model-item prop="surface_plot" label="封面图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="up_img_surface_plot"
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="content_surface_plot_image"
                  ref="content_surface_plot_original_image" />
            </div>
            <div
                class="upload_LiveBroadcast">
              <img
                  v-if="videoForm.surface_plot"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="videoForm.surface_plot"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注：尺寸 1920*520，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item prop="video" label="视频">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="up_video_surface_plot"
                  style="width: 100%;height:100%;opacity: 0;"
                  type="file" accept=".mp4"
                  @change="content_surface_plot_original_video($event)" />
            </div>
            <div
                @click="openVideoUrl(videoForm.video)"
                class="upload_LiveBroadcast">
              <video
                  style="width: 100%;height:100%"
                  :src="videoForm.video"></video>
            </div>
          </div>
          <div style="color: #a1a1a1">注：尺寸 640*360，大小不超过500kb</div>
        </a-form-model-item>
        <a-form-model-item  prop="title" label="标题 ">
          <a-input
              v-model="videoForm.title"
              style="width: 370px"
              placeholder="请输入标题"
          />
        </a-form-model-item>
        <a-form-model-item  prop="specialist" label="专家">
          <a-select
              @popupScroll="popupScroll_specialist"
              @search="select_specialist"
              v-model="videoForm.specialist"
              show-search
              placeholder="请选择栏目"
              option-filter-prop="children"
              style="width: 370px"
          >
            <a-select-option
                v-for="(experts,index) in specialist_experts"
                :key="index"
                @click="video_demand_handleChange"
                :value="experts.id">
              {{experts.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item  prop="include" label="介绍 ">
          <div ref="editor"></div>
        </a-form-model-item>
        <a-form-model-item  prop="titleStart" label="片头">
          <a-select
              @popupScroll="popupScroll_start"
              @search="select_start"
              v-model="videoForm.titleStart"
              show-search
              placeholder="请选择"
              option-filter-prop="children"
              style="width: 340px"
          >
            <a-select-option
                v-for="(Starts,index) in startList"
                :key="index"
                :value="Starts.shortId">
              {{Starts.title}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item  prop="titleEnd" label="片尾">
          <a-select
              @popupScroll="popupScroll_end"
              @search="select_end"
              v-model="videoForm.titleEnd"
              show-search
              placeholder="请选择"
              option-filter-prop="children"
              style="width: 340px"
          >
            <a-select-option
                v-for="(ends,index) in endList"
                :key="index"
                :value="ends.shortId">
              {{ends.title}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item  prop="original" label="点播单视频">
          <a-tooltip placement="top">
            <template slot="title">
              <span>prompt text</span>
            </template>
            <a-icon style="margin-right: 10px" type="info-circle" />
          </a-tooltip>
          <a-switch
              style="margin-right: 5px"
              v-model="videoForm.singleVideoSwitch === 1?true:false"
              checked-children="是"
              un-checked-children="否"
              @click="singleVideoSwitchChange(videoForm.singleVideoSwitch === 1? 0 : 1)"
              default-checked />
          <a-select
              @popupScroll="popupScroll_singleVideo"
              @search="select_singleVideo"
              v-if="videoForm.singleVideoSwitch"
              v-model="videoForm.singleVideo"
              show-search
              placeholder="请选择"
              option-filter-prop="children"
              style="width: 290px"
          >
            <a-select-option
                v-for="(columns,index) in columnList"
                :key="index"
                :value="columns.code">
              {{columns.title}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item  prop="original" label="点播专题">
          <a-tooltip placement="top">
            <template slot="title">
              <span>prompt text</span>
            </template>
            <a-icon style="margin-right: 10px" type="info-circle" />
          </a-tooltip>
          <a-switch
              style="margin-right: 5px"
              v-model="videoForm.demandProjectSwitch === 1?true:false"
              checked-children="是"
              un-checked-children="否"
              @click="demandProjectSwitchChange(videoForm.demandProjectSwitch === 1? 0 : 1)"
              default-checked />
          <a-select
              @popupScroll="popupScroll_demandProject"
              @search="select_demandProject"
              v-if="videoForm.demandProjectSwitch"
              v-model="videoForm.demandProject"
              show-search
              placeholder="请选择"
              option-filter-prop="children"
              style="width: 290px"
          >
            <a-select-option
                v-for="(specials,index) in specialsList"
                :key="index"
                :value="specials.id">
              {{specials.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="close_video">
          取消
        </a-button>
        <a-button
            style="margin-left: 10px"
            type="primary" @click="onsubmit_video">
          确定
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import moment from "moment";
import {update} from "../../../utils/update";
import E from "wangeditor";
import {
  del_videoList,
  get_column_vods, get_demand_playback_one_list,
  get_experts_id,
  get_signature, get_specials_list,
  get_startEnd,
  get_videoLink, post_video, put_video, put_video_topic
} from "@/service/MedicalConference_y";
import axios from "axios";
const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    width:'50%',
    scopedSlots: { customRender: 'name' },
  },{
    title: '作者',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'names' },
  },{
    title: '创建时间',
    dataIndex: 'created_time',
    key: 'created_time',
    width:'15%',
  },{
    title: '操作',
    key: 'action',
    width:'10%',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  name:'videoLibrary',
  data(){
    return{

      pageNum:1,
      data:[],
      videoForm:{
        surface_plot:'',
        video:'',
        title:'',
        specialist:undefined,
        include:'',
        titleStart:undefined,
        titleEnd:undefined,
        singleVideo:undefined,
        singleVideoSwitch:0,
        demandProject:undefined,
        demandProjectSwitch:0,
        vType:'',
      },
      content_video_rules:{
        surface_plot: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        video: [{ required: true, message: '请上传视频', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        specialist: [{ required: true, message: '请输入专家', trigger: 'blur' }],
        include: [{ required: true, message: '请输入介绍', trigger: 'blur' }],
        titleStart: [{ required: true, message: '请选择片头', trigger: 'blur' }],
        titleEnd: [{ required: true, message: '请选择片尾', trigger: 'blur' }],
      },
      labelCol: { span: 3},
      wrapperCol: { span: 14 },
      columns,
      visible_upVideo:false,
      yearShowOne:false,
      year:'',
      expertsName:'',
      videoTitle:'',
      editor:null,
      editor_menus:[
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        'video', // 插入视频
        'code', // 插入代码
        'undo', // 撤销
        'redo', // 重复
        'fullscreen' // 全屏
      ],
      total:0,
      tableId:'',
      vFile:'',
      signature:'',
      yunVideo_fileId:'',
      Video_fileId:'',
      vUploader: {}, // 腾讯云上传的初始化对象
      specialist_experts:[],
      specialist_page_no:1,
      specialist_title:'',
      startList:[],
      startList_page_no:1,
      startList_title:'',
      endList:[],
      endList_page_no:1,
      endList_title:'',
      columnList:[],
      specialsList:[],
      startEndList:[],
      oldVodId:null,
      specialsList_page_no:1,
      specialsList_title:'',
      manageId:null,
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle','视频库管理')
    this.getList()

  },
  methods:{
    //获取列表
    async getList() {
      let data = {
        title:this.videoTitle,
        name:this.expertsName,
        year:this.year,
        pageNum:this.pageNum,
        pageSize:10,
        type:'video',
      }
      const response = await get_column_vods(data)
      if(response.code === 0){
        this.data = response.data[0].vodContents
        this.total = response.data[0].count
      }else {
        this.$message.warning(response.message)
      }
    },
    //获取专家列表
    async getExpertsId(name,pageNum,pageSize) {
      const response = await get_experts_id(name,pageNum,pageSize)
      if(response.code === 0){
        // this.specialist_experts = response.data
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.specialist_experts.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }else {
        this.$message.warning(response.message)
      }
    },
    //滚动条监听
    popupScroll_specialist(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.specialist_page_no++
        this.getExpertsId(this.specialist_title,this.specialist_page_no)
      }
    },
    //鼠标搜索
    select_specialist(value){
      //首先清空数组，否则会数据重复
      this.specialist_experts = []
      this.specialist_title = value
      this.getExpertsId(value,1)
    },
    //获取片头片尾
    async getStartEndList(pageNum,pageSize,type,title) {
      const response = await get_startEnd(type,pageNum,pageSize,title)
      if(response.code === 0){

        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.startEndList.push({
            shortId: list[i].shortId,
            title: list[i].title,
          })
        }
        if(type === 'start'){
          this.startList = this.startEndList
        }else {
          this.endList = this.startEndList
        }
      }else {
        this.$message.warning(response.message)
      }
    },
    //滚动条监听
    popupScroll_start(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.startList_page_no++
        this.getStartEndList(this.startList_page_no,10,'start',this.startList_title)
      }
    },
    //鼠标搜索
    select_start(value){
      //首先清空数组，否则会数据重复
      this.startEndList = []
      this.startList = []
      this.startList_title = value
      this.getStartEndList(1,10,'start',value)
    },
    //滚动条监听
    popupScroll_end(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.endList_page_no++
        this.getStartEndList(this.endList_page_no,10,'end',this.endList_title)
      }
    },
    //鼠标搜索
    select_end(value){
      //首先清空数组，否则会数据重复
      this.startEndList = []
      this.endList = []
      this.endList_title = value
      this.getStartEndList(1,10,'end',value)
    },
    //获取点播单视频 --- 栏目
    async getcolumnsList(pageNum) {
      let data = {
        // title:this.videoTitle,
        // name:this.expertsName,
        pageNum:pageNum,
        pageSize:10,
        type:'vod',
      }
      const response = await get_column_vods(data)
      if(response.code === 0){
        this.columnList = response.data
      }else {
        this.$message.warning(response.message)
      }
    },
    //滚动条监听
    popupScroll_singleVideo(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.specialist_page_no++
        this.getExpertsId(this.specialist_title,this.specialist_page_no)
      }
    },
    //鼠标搜索
    select_singleVideo(value){
      //首先清空数组，否则会数据重复
      this.specialist_experts = []
      this.specialist_title = value
      this.getExpertsId(value,1)
    },
    // 点播专题
    async getspecialsList(pageNum,title) {
      let data = {
        title:title,
        pageNum:pageNum,
        pageSize:10,
      }
      const response = await get_specials_list(data)
      if(response.code === 0){
        // this.specialsList = response.data
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.specialsList.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }else {
        this.$message.warning(response.message)
      }
    },
    //滚动条监听
    popupScroll_demandProject(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.specialsList_page_no++
        this.getspecialsList(this.specialsList_page_no,this.specialsList_title)
      }
    },
    //鼠标搜索
    select_demandProject(value){
      //首先清空数组，否则会数据重复
      this.specialsList = []
      this.specialsList_title = value
      this.getspecialsList(1,value)
    },
    //富文本编辑器
    async create_editor_config() {
      //等待DOM加载完毕
      const editor = new E(this.$refs.editor)
      this.editor = editor
      //去除图片视频上传
      editor.config.excludeMenus = [
        'image',
        'video'
      ]
      // 设置编辑区域高度为 500px
      editor.config.height = 200
      //创建编辑器
      await editor.create()
    },
    // 点播专题
    async videoListOne(id) {
      const response = await get_demand_playback_one_list(id)
      if(response.code === 0){

        let form = this.videoForm
        let data = response.data
        //itemId--manageId
        this.manageId = data.itemId

        form.surface_plot = data.cover_img
        form.video = data.media_url
        this.Video_fileId = data.file_id
        form.title = data.title
        form.specialist = data.expert_id
        //介绍
        form.titleStart = data.startId
        form.titleEnd = data.endId


        if (data.code) {
          form.singleVideoSwitch = 1
          form.singleVideo = data.code
        } else {
          form.singleVideoSwitch = 0
        }

        this.oldVodId = data.maxId

        if (data.videoTitle) {
          form.demandProjectSwitch = 1
          form.demandProject = data.videoTitle
        } else {
          form.demandProjectSwitch = 0
        }

        if(response.data.intro){
          this.editor.txt.html(response.data.intro)
        }

        await this.getExpertsId()
        //片头
        await this.getStartEndList(1,10,'start')
        //片尾
        await this.getStartEndList(1,10,'end')

        //点播单视频----栏目
        await this.getcolumnsList()
        //点播专题
        await this.getspecialsList()
      }else {
        this.$message.warning(response.message)
      }
    },
    onChange(pageNumber){
      this.pageNum = pageNumber
      this.getList()
    },
    restBtn_table(row) {
      //专家 selsct
      this.specialist_page_no = 1
      this.specialist_experts = []

      //专题 select
      this.specialsList_page_no = 1
      this.specialsList = []

      //片头片尾
      this.startEndList = []
      this.startList = []
      this.endList = []
      this.startList_page_no = 1
      this.endList_page_no = 1

      this.tableId = row.id

      //获取单个视频内容
      this.videoListOne(row.id)

      this.visible_upVideo = true

      this.$nextTick(() => {
        if (this.editor === null) {
          this.create_editor_config()
        } else {
          this.editor.destroy()
          this.create_editor_config()
        }
      })
    },
    async demandProjectChange() {
      //修改的情况下
      if(this.tableId){
        const response = await put_video_topic(this.oldVodId,this.videoForm.demandProject)
        if (response.code === 0) {
        } else {
          this.$message.warning(response.message)
        }
      }
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }else {
        this.yearShowOne = false;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.year = value;
      if(this.year){
        this.year = moment(this.year).format('YYYY')
      }
    },
    lookBtn(){
      this.getList()
    },
    restBtn(){
      this.year = ''
      this.expertsName = ''
      this.pageNum = 1
      this.videoTitle = ''
      this.getList()
    },

    newProject(){
      //专家 select
      this.specialist_page_no = 1
      this.specialist_experts = []

      //专题 select
      this.specialsList_page_no = 1
      this.specialsList = []
      //片头片尾
      this.startEndList = []
      this.startList = []
      this.endList = []
      this.startList_page_no = 1
      this.endList_page_no = 1
      this.getExpertsId()
      //片头
      this.getStartEndList(this.startList_page_no,10,'start')
          //片尾
      this.getStartEndList(this.endList_page_no,10,'end')

          //点播单视频----栏目
      this.getcolumnsList()
      //点播专题
      this.getspecialsList()
      this.visible_upVideo = true
      this.$nextTick(() => {
        if(this.editor === null){
          this.create_editor_config()
        }else {
          this.editor.destroy()
          this.create_editor_config()
        }
      })
    },
    async titleBtn(id) {
      const response = await get_demand_playback_one_list(id)
      if (response.code === 0) {
        window.open(response.data.media_url)
      } else {
        this.$message.warning(response.message)
      }
    },
    table_restBtn(id){

    },
    table_delBtn(id){

    },
    async confirm(id) {
      const response = await del_videoList(id)
      if (response.code === 0) {
        this.$message.success("删除成功~")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    close_video(){
      this.visible_upVideo = false
      this.$refs.content_ruleForm.resetFields();
      // this.videoForm.video = ''
      this.videoForm.demandProjectSwitch = 0
      this.videoForm.demandProject = undefined
      this.videoForm.singleVideoSwitch = 0
      this.videoForm.singleVideo = undefined
      this.yunVideo_fileId = ''
      this.Video_fileId = ''
      this.tableId = null
      this.oldVodId = null
      this.manageId = null
      //专家select
      this.specialist_title = ''
      this.specialist_page_no = 1
    },
    // 上传腾讯云
    uploadVideoFile_toyun(){
      let _this = this
      const getSignature = () => {
        return _this.signature;
      }
      const tcVod = new _this.$TcVod({
        getSignature: getSignature // 前面的获取上传签名的函数
      })
      const mediaFile = this.vFile
      this.vUploader = tcVod.upload({
        mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
      })
      this.vUploader.done().then(res => { // 上传完成回调
        //视频上传后获取到的视频fileId
        this.yunVideo_fileId = res.fileId
        //获取视频url地址
        this.content_video_url(res.fileId)
        this.$message.success('视频文件上传成功')
      })
    },
    async content_video_url(videoId) {
      const response = await get_videoLink(videoId)
      if (response.code === 0) {
        this.videoForm.video = response.data.mediaUrl
      }else {
        this.$message.warning(response.message)
      }
    },
    //获取签名
    async content_surface_plot_getSignature() {
      const response = await get_signature()
      if (response.code === 0) {
        this.signature = response.data.signature;
      }
    },
    async content_surface_plot_original_video(e) {
      if (e.currentTarget.files[0]) { // 选择了文件
        if (e.currentTarget.files[0].type === 'video/mp4') {
          this.vFile = e.currentTarget.files[0] // 获取上传文件中的File对象信息
          document.getElementById('up_video_surface_plot').value = null;
          await this.content_surface_plot_getSignature()
          await this.uploadVideoFile_toyun()
        } else {
          this.$message.warning('仅支持mp4格式的视频上传')
        }
      } else { // 取消选择文件
        this.vFile = {}
        this.vIsFileExist = false
      }
    },
    openVideoUrl(url){
      window.open(url)
    },
    async content_surface_plot_image() {
      let inputDOM = this.$refs.content_surface_plot_original_image.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.videoForm.surface_plot = image.data.url
          this.$message.success("上传成功")
          document.getElementById('up_img_surface_plot').value = null;
        } else {
          this.$message.error("上传失败，请重试。", image.message)
        }
      } else {
        this.$message.warning("请上传小于500KB的图片！")
      }
    },
    video_demand_handleChange(){

    },
    singleVideoSwitchChange(isOpen){
      this.videoForm.singleVideoSwitch = isOpen
    },
    demandProjectSwitchChange(isOpen){
      this.videoForm.demandProjectSwitch = isOpen
    },
    liveRoomChange(){

    },
    onsubmit_video(){
      this.videoForm.include = this.editor.txt.html()
      this.$refs.content_ruleForm.validate(valid =>{
        if(valid){
          let form = this.videoForm
          let vType;
          if(form.demandProjectSwitch){
            vType = 'VOD_SET_TOPIC'
          }else {
            vType = 'VOD_SINGLE'
          }
          //  判断按钮  type ------- VOD_SINGLE 点播 VOD_SET_TOPIC专题
          // if( form.singleVideoSwitch === 1 &&  form.demandProjectSwitch === 1 ){
          //   vType = 'VOD_SINGLE'
          // }else if(form.singleVideoSwitch === 1 &&  form.demandProjectSwitch === 0){
          //   vType = 'VOD_SINGLE'
          // } else if(form.singleVideoSwitch === 0 &&  form.demandProjectSwitch === 1){
          //   vType = 'VOD_SET_TOPIC'
          // } else if(form.singleVideoSwitch === 0 &&  form.demandProjectSwitch === 0){
          //   vType = 'VOD_SINGLE'
          // }
          if(this.tableId){
          //  修改
            let data = {
              title: form.title,
              //必填，若不修改择填原本的fileId
              fileId:this.Video_fileId,//视频的id
              cover: [form.surface_plot],
              cover_img: form.surface_plot,
              intro: form.include,
              expert_id: form.specialist,
              startId: form.titleStart,
              endId: form.titleEnd,
              column_code: form.singleVideo,
              // vodId: form.demandProject
            }
            //必填，若不修改择填原本的fileId
            if(!this.Video_fileId){
              this.Video_fileId = this.yunVideo_fileId
            }
            this.restList(this.tableId,this.Video_fileId,data)
            let restData = {
              cover:[form.surface_plot],
              manageId:this.manageId,
              title:form.title,
              vodId:form.demandProject
            }
            //有专题的情况
            if(this.oldVodId){
              this.demandProjectChange()
            }else {
              //没有
              this.addTable(this.Video_fileId,'max',restData)
            }
          }else {
          //  新增
            let dataAdd = {
              cover: [form.surface_plot],    //封面
              coverImg: form.surface_plot,    //视频封面
              title: form.title,    //标题
              expertId: form.specialist,    //专家id
              intro: form.include,    //介绍
              startId: form.titleStart,    //片头id
              endId: form.titleEnd,    //片尾id
              column_code: form.singleVideo,    //栏目代码 --点播单视频
              vodId: form.demandProject,   // 点播专题
              videoType:vType
            }
            if(dataAdd.column_code === undefined){
              dataAdd.column_code = ""
            }
            this.addTable(this.yunVideo_fileId,'Video',dataAdd)

          }
          //  清空对话框
          this.visible_upVideo = false
          this.$refs.content_ruleForm.resetFields();
          // this.videoForm = {}
          // this.videoForm.video = ''
          this.videoForm.demandProject = undefined
          this.videoForm.singleVideo = undefined
          this.videoForm.demandProjectSwitch = 0
          this.videoForm.singleVideoSwitch = 0
          this.yunVideo_fileId = ''
          this.Video_fileId = ''
          this.tableId = null
          this.manageId = null
          this.oldVodId = null
          //专家select
          this.specialist_title = ''
          this.specialist_page_no = 1
          //专题 select
          this.specialsList_page_no = 1
          this.specialsList_title = ''
        }else {
          this.$message.warning("表单有空~")
          return false;
        }
      })

    },
    //新增
    async addTable(fileId,type,data) {
      const response = await post_video(fileId, type, data)
      if (response.code === 0) {
        this.$message.success("操作成功~")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    //修改
    async restList(id,fileId,data) {
      const response = await put_video(id,'video',fileId,data)
      if (response.code === 0) {
        this.$message.success("修改成功~")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.videoLib-top{
  width: 100%;
  border-bottom: 13px solid #f0f2f5;
  height: 100px;
  display: flex;
  padding-top: 2%;
  justify-content: space-around;
  .input{
    width: 65%;
    margin-left: 10px;
  }
}
.videoLib-content{
  min-height: 600px;
  width: 97%;
  margin: auto;
    .r-btn{
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_LiveBroadcast{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  margin-left: 20px;
  text-align: center;
}
</style>